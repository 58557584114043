<template>
    <div class="card">
        <img class="iconimg" :src="getIconPath(icon)" v-if="icon" />
        <h3>{{ title }}</h3>
        <a>{{ description }}</a>
        <a class="link" :href=link>{{ linkText }} ></a>
    </div>
</template>

<script>
    export default {
    name: 'card',
    props: {
        icon: String,
        title: String,
        description: String,
        link: String,
        linkText: String
    },
    methods: {
        getIconPath(icon) {
            if (!icon) return ''
            return require(`@/assets/${icon}`)
        }
    }
}
</script>

<style scoped>
.card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 300px;
    padding: 30px;
}
.iconimg {
    width: 80px;
    height: 80px;
    border-radius: 10px;
}
.link {
    text-decoration: none;
    color: white;
}
</style>
  